<template>
  <div>
    <div class="super">
      <div class="holder">
        <Header />
        <!-- Hero section starts -->
        <div class="first-hero">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 hero-row">
                <div class="first-hero-left">
                  <h1>You Build, We Pay.</h1>
                  <p>
                    Shelta guarantees you get your property rental payment
                    annually on due date with no delay, no stress.
                  </p>
                  <div class="first-hero-btn">
                    <button class="btn">
                      <router-link to="/signup">
                        Create a free account
                      </router-link>
                    </button>
                  </div>
                  <div
                    class="mobile-hero col-md-6 d-block d-sm-none d-none d-sm-block d-md-none"
                  >
                    <img
                      src="../assets/home/tenant-hero-mobile.svg"
                      alt="hero"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-none d-md-block">
                <div class="mobile-hero first-hero-right">
                  <img src="../assets/home/tenant-hero.svg" alt="hero" />
                </div>
              </div>
              <!-- <div
                class="mobile-hero col-md-6 d-block d-sm-none d-none d-sm-block d-md-none"
              >
                <img src="../assets/home/tenant-hero-mobile.svg" alt="hero" /> -->
              </div>
            </div>
          </div>
        </div>
        <!-- First Hero section ends -->

        <!-- Cards start -->
        <div class="cards-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/rpicon.svg" alt="rent-payment" />
                  <h4>Timely Rental Payment</h4>
                  <p>
                    We understand you want to collect rent yearly. We are not
                    here to change that but to make it better. With Shelta, your
                    annual property rental payment is guaranteed for up to 10
                    years.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/fmicon.svg" alt="fm" />
                  <h4>Facility Management</h4>
                  <p>
                    We have a team of professionals dedicated to supporting you
                    and yours. We ensure the functionality, comfort, safety,
                    sustainability and efficiency of your properties.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-holder">
                  <img src="../assets/tenant/lcicon.svg" alt="lc" />
                  <h4>Litigation cover</h4>
                  <p>
                    Your property is a huge investment. With Shelta, we insure
                    you against litigation from tenants to protect you and your
                    property. T & C Applies. Now, you can sleep better knowing
                    we got you covered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cards ends -->
        <HomePart />
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import { Service } from "../store/service";
import HomePart from "../components/HomePart.vue";
import $ from "jquery";
const Api = new Service();

export default {
  name: "LandlordPage",
  components: {
    Header,
    FooterMain,
    HomePart
  },
  data() {
    return {
      theClient: "landlord"
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$sheltaBlack: #000a2f;
$primary: #0033ea;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$offGray: #555c74;
$bg: #f9f9ff;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  position: relative;
  // background: $whiteText;
}

.first-hero {
  margin-top: 7rem;
  margin-bottom: 20px;
  // padding-top: 4rem;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 125%;
    color: $sheltaBlack;
    text-align: left;
    padding-top: 14rem;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
    text-align: left;
    padding-right: 90px;
  }
  .first-hero-btn {
    text-align: left;
    padding-top: 30px;
    button {
      width: 183px;
      height: 56px;
      background: $primary;
      border-radius: 6px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: $whiteText;
      box-shadow: none;
      outline: 0;
    }
  }
}
.hero-row {
  padding: 0;
}
.first-hero-left {
  padding-left: 77px;
  background: $bg;
  border-radius: 0px 0px 200px 0px;
  // padding-bottom: 16rem;
  height: 700px;
}
.first-hero-right {
  img {
    width: 100%;
    height: 700px;
  }
}

@media only screen and (max-width: 599px) {
  .hero-row {
    padding: 0;
  }
  .first-hero-left {
    border-radius: 0;
    padding-left: 0px;
    padding-top: 0;
    // height: 632px;
    height: fit-content;
    padding: 0 10px;
    // background: url("../assets/home/tenant-hero-mobile.svg") no-repeat center center;
    // background: linear-gradient(
    //     180deg,
    //     rgba(255, 255, 255, 0.75) 82.81%,
    //     rgba(255, 255, 255, 0) 100%
    //   ),
    //   url("../assets/home/tenant-hero.svg") no-repeat center center;
    h1 {
      padding-top: 3rem;
      font-size: 32px;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
    }
    p {
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
    }
  }
  .first-hero {
    .first-hero-btn {
      padding-top: 10px;
      text-align: left;
      margin-right: 15px;
      margin-left: 15px;
      button {
        height: 44px;
      }
    }
  }
  .mobile-hero {
    padding-top: 30px;
    // background: $bg;
    img {
      // width: -webkit-fill-available;
      // width: 611px;
      width: 100%;
    }
  }
  .first-hero-right {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// / Card section starts
.cards-section {
  padding: 60px 77px;
}
.card-holder {
  padding: 20px;
  border: 0.5px solid #d4d2e3;
  border-radius: 10px;
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: $sheltaBlack;
    padding-top: 10px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: $offGray;
    margin-bottom: 0;
    // padding-top: 10px;
  }
}

@media only screen and (max-width: 599px) {
  .cards-section {
    padding: 30px 0;
  }
  .card-holder {
    margin-bottom: 24px;
    // border: none;
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
// Card section ends
</style>
